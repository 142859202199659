import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';

import { createMarkup } from '../utils';
import { postRequest } from '../utils/apiUtils';

import CheckBox from './UIComponents/Checkbox';
import { Text, Title } from './UIComponents/Typography';

export default function OfferModal({
  show,
  onClose,
  retailers = [],
  urn,
  models,
  modelName,
  vehicle,
}) {
  const { t } = useTranslation();
  const [isValid, setIsValid] = React.useState(false);
  const [isTermsChecked, setIsTermsChecked] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [isSomeFieldChanged, setIsSomeFieldChanged] = React.useState(false);
  const [formValues, setFormValues] = React.useState(() => {
    const currentModel = modelName
      ? modelName
      : models.find(model => model.URN === urn)?.ModelName ?? '';

    const placeholderText = `${t('UserCommentDefaultText')} ${currentModel}`;

    return {
      Comment: placeholderText,
    };
  });

  const handleFieldChange = e => {
    if (!isSomeFieldChanged) {
      window.tngaDataLayer.event = {
        name: 'formevent',
        componentName: 'offer-request',
        componentGroup: 'forms',
        action: 'form-step',
        label: 'contact-details',
      };
      window.tngaDataLayer.form = {
        name: 'lead-offer-request',
        step: 'contact-details',
        conversion: '0',
      };
      window.tngaDataLayer.product = [
        {
          category: 'cars',
          subCategory: 'new-cars',
          productInfo: {
            productId: vehicle?.ModelCodeForGTag,
            productName: vehicle?.ModelNameForGTag,
            tmeModelId: vehicle?.ModelCodeForGTag,
            modelName: vehicle?.ModelNameForGTag,
          },
        },
      ];
      window.dataLayer.push({ event: 'formevent' });
    }
    setIsSomeFieldChanged(true);
    const id = e.target.id;
    if (id === 'Terms') setIsTermsChecked(e.target.value);

    setFormValues(formValues => ({
      ...formValues,
      [id]: e.target.value,
    }));
  };

  const handleClose = () => {
    setIsValid(false);
    setFormValues({});
    setIsTermsChecked(false);
    onClose();
  };

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.currentTarget;
    const isTermsAccepted = formValues?.Terms;

    if (!isTermsAccepted) setIsTermsChecked('notChecked');

    if (form.checkValidity() === true && isTermsAccepted) {
      const postBody = { ...formValues, CarId: urn };
      delete postBody.Gdpr;
      delete postBody.Terms;

      fetch('/api/offerRequest', postRequest(postBody)).then(res => {
        if (res.ok) {
          const retailer = retailers.find(
            r => r.WebServiceId === Number(formValues.RetailerId)
          );

          window.tngaDataLayer.event = {
            name: 'formevent',
            componentName: 'offer-request',
            componentGroup: 'forms',
            action: 'form-step',
            label: 'form-submit',
          };
          window.tngaDataLayer.form = {
            name: 'lead-offer-request',
            step: 'form-submit',
            conversion: '1',
          };
          window.tngaDataLayer.product = [
            {
              category: 'cars',
              subCategory: 'new-cars',
              productInfo: {
                productId: vehicle.ModelCodeForGTag,
                productName: vehicle.ModelNameForGTag,
                tmeModelId: vehicle.ModelCodeForGTag,
                modelName: vehicle.ModelNameForGTag,
              },
            },
          ];
          window.tngaDataLayer.dealer = {
            name: retailer.Name,
            dealerId: retailer.WebServiceId,
          };
          window.dataLayer.push({ event: 'formevent' });
        }
        return res.ok ? setIsSubmitted(true) : setIsSubmitted('error');
      });
    }

    setIsValid(true);
  };

  return (
    <Modal size="lg" show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        {!isSubmitted ? (
          <Modal.Title className="mx-auto">
            {t('WantMoreInformationAboutCar')}
          </Modal.Title>
        ) : isSubmitted === 'error' ? (
          <Modal.Title className="mx-auto">{t('Error')}</Modal.Title>
        ) : (
          <Modal.Title className="mx-auto">
            {t('ThankYouForTheRequest')}
          </Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body className={isSubmitted && 'form-submitted'}>
        {!isSubmitted ? (
          <Form noValidate validated={isValid} onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group controlId="Name">
                  <Form.Label className="required">{t('Name')}</Form.Label>
                  <Form.Control
                    onKeyUp={handleFieldChange}
                    required
                    type="text"
                  />
                  <Form.Control.Feedback type="invalid">
                    <Trans i18nKey="RequiredField">
                      {{ field: t('Name') }} on kohustuslik
                    </Trans>
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="PhoneNo">
                  <Form.Label className="required">{t('Phone')}</Form.Label>
                  <Form.Control
                    onKeyUp={handleFieldChange}
                    required
                    type="text"
                  />
                  <Form.Control.Feedback type="invalid">
                    <Trans i18nKey="RequiredField">
                      {{ field: t('Phone') }} on kohustuslik
                    </Trans>
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group controlId="Email">
                  <Form.Label className="required">{t('Email')}</Form.Label>
                  <Form.Control
                    onKeyUp={handleFieldChange}
                    required
                    type="email"
                  />
                  <Form.Control.Feedback type="invalid">
                    <Trans i18nKey="RequiredField">
                      {{ field: t('Email') }} on kohustuslik
                    </Trans>
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="RetailerId">
                  <Form.Label className="required">{t('Retailer')}</Form.Label>
                  <Form.Control
                    required
                    onChange={handleFieldChange}
                    as="select"
                    custom
                  >
                    <>
                      <option value={null}></option>
                      {retailers.map(({ MarketingName, WebServiceId }) => (
                        <option key={WebServiceId} value={WebServiceId}>
                          {MarketingName}
                        </option>
                      ))}
                    </>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    <Trans i18nKey="RequiredField">
                      {{ field: t('Retailer') }} on kohustuslik
                    </Trans>
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group controlId="Comment">
              <Form.Label className="required">{t('Comment')}</Form.Label>
              <Form.Control
                required
                onKeyUp={handleFieldChange}
                as="textarea"
                rows={3}
                defaultValue={formValues.Comment}
              />
              <Form.Control.Feedback type="invalid">
                <Trans i18nKey="RequiredField">
                  {{ field: t('Comment') }} on kohustuslik
                </Trans>
              </Form.Control.Feedback>
            </Form.Group>
            <div dangerouslySetInnerHTML={createMarkup(t('GDPR'))} />
            <Form.Group controlId="Terms">
              <CheckBox
                id="Terms"
                checked={formValues.Terms}
                onChange={handleFieldChange}
              />
              <label
                htmlFor="Terms"
                dangerouslySetInnerHTML={createMarkup(t('Confirmation'))}
              />
              <div
                className={`invalid-feedback ${
                  isTermsChecked === 'notChecked' && 'feedback-displayed'
                }`}
              >
                {t('EMailAgreement')}
              </div>
            </Form.Group>
            <Row className="footer justify-content-end my-5">
              <Button
                variant="secondary"
                onClick={handleClose}
                size="sm"
                type="button"
              >
                {t('Close')}
              </Button>
              <Button className="ml-4" size="sm" type="submit">
                {t('Send')}
              </Button>
            </Row>
          </Form>
        ) : isSubmitted === 'error' ? (
          <Title type="error" size="4">
            {t('ErrorUnabelToSendMail')}
          </Title>
        ) : (
          <Text>{t('RetailerWillContactYou')}</Text>
        )}
      </Modal.Body>
    </Modal>
  );
}
